import React, {Fragment} from 'react';
import * as _ from 'lodash';
import {observer} from 'mobx-react';
import {loadStripe} from '@stripe/stripe-js/pure';
import {ConfirmCardSetupData, SetupIntent, Stripe, StripeElements, StripeElementsOptions} from '@stripe/stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import {PaymentMethodCreateParams} from '@stripe/stripe-js/types/api/payment-methods';
import {SetupIntentResult} from '@stripe/stripe-js/types/stripe-js/stripe';
import {Button, Radio, Row} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';

import Value from 'helpers/Value';
import {ComponentWithStore, withStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';
import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';
import {CreditCardApi} from 'models/creditCard/CreditCardApi';
import {ICreditCardApiData} from 'models/creditCard/ICreditCardApiData';

import '../stripe.scss'
import './style.scss'
import {LoadingOutlined} from "@ant-design/icons";
import CouponForm from "components/public/coupons/CouponForm";
import Coupon from "models/coupon/Coupon";

loadStripe.setLoadParameters({advancedFraudSignals: false});

interface ICreditCardProps {
  stripe: Stripe;
  elements: StripeElements;
  options: StripeElementsOptions;
  billingAddress: BillingAddress;
  subscriptionPlan?: SubscriptionPlan;
  coupon?: Coupon | null;
  disableSave?: boolean;
  onCouponAdded?: (coupon: Coupon) => Coupon | null;
  onSave: (stripeSetupIntentId: string, creditCardId: number) => Promise<any>;
  editBillingAddress: (event: React.MouseEvent) => void;
  editSubscriptionPlan?: (event: React.MouseEvent) => void;

  hideHeader?: boolean;

  buttonText?: string;
  errorMessage?: { name: string, message: string } | null;
}

interface ICreditCardState {
  errorMessage: string;
  submitting: boolean;
  newCreditCard: boolean;
  creditCards: ICreditCardApiData[];
  selectedCreditCardId?: number;
  coupon: Coupon | null;
}

class StripePaymentForm extends ComponentWithStore<ICreditCardProps, ICreditCardState> {
  private readonly disableSave: boolean;
  private mounted = false;
  private loadedCards = false;
  private setupIntent: SetupIntent | null = null;

  constructor(props: ICreditCardProps) {
    super(props);

    this.disableSave = Value.defaultFalse(this.props.disableSave);

    this.state = {
      errorMessage: '',
      submitting: false,
      newCreditCard: true,
      creditCards: [],
      coupon: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectCreditCard = this.handleSelectCreditCard.bind(this);
    this.handleCoupon = this.handleCoupon.bind(this);
  }

  public componentDidMount(): void {
    this.mounted = true;
    if (!this.loadedCards) {
      this.getCreditCards();
    }
  }

  public getCreditCards() {
    CreditCardApi.getAll(this.store.SessionProvider.userId())
      .then((creditCards: ICreditCardApiData[]) => {
        if (creditCards && this.mounted) {
          this.loadedCards = true;
          const {selectedCreditCardId} = this.state;
          let newSelectedCreditCardId = selectedCreditCardId;
          if (creditCards.length > 0 && !selectedCreditCardId) {
              newSelectedCreditCardId = creditCards[0].id;
          }
          this.setState({
            newCreditCard: creditCards.length === 0,
            creditCards: creditCards,
            selectedCreditCardId: newSelectedCreditCardId
          });
        }
      });
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  private handleSelectCreditCard(e: RadioChangeEvent): void {
    this.setState({
      selectedCreditCardId: e.target.value
    });
  };

  private handleCoupon(coupon) {
    const { onCouponAdded } = this.props;
    // this.setState({
    //   coupon: coupon
    // });
    onCouponAdded(coupon);
    return coupon;
  }

  public async handleSubmit(event) {
    event.preventDefault();

    const {stripe, elements, billingAddress, onSave} = this.props;
    const {newCreditCard} = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('Stripe or Elements are not loaded...')
      return;
    }
    this.setState({errorMessage: null, submitting: true});
    if (billingAddress != null && billingAddress.id == null) {
      await billingAddress.save();
    }

    return this.confirmCardSetup().catch((error) => {
        console.log('confirmCardSetupError', error);
      })
      .then(({setupIntent, error}: SetupIntentResult) => {
        console.log('setupIntent!', setupIntent);
        if (error) {
          console.log(error);
          this.setState({
            errorMessage: error.message,
            submitting: false
          });
          throw error;
        }

        return setupIntent;
      })
      .then((setupIntent: SetupIntent) => stripe.retrieveSetupIntent(setupIntent.client_secret))
      .then(({setupIntent, error}: SetupIntentResult) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

        if (error) {
          console.log(error);
          this.setState({
            errorMessage: error.message,
            submitting: false
          });
          throw error;
        }

        this.setupIntent = setupIntent;
        switch (setupIntent.status) {
          case 'processing':
            this.setState({errorMessage: 'Processing payment details. We\'ll update you when processing is complete.'});
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            let message: string = 'Failed to process payment details. Please try another payment method.';
            this.setState({errorMessage: message});
            throw new Error(message);
        }

        if (newCreditCard) {
          return CreditCardApi.create({
            userId: this.store.SessionProvider.userId(),
            billingAddressId: billingAddress?.id,
            paymentMethod: setupIntent.payment_method
          }).then((json: { credit_card: { id: number } }) => onSave(setupIntent.id, json.credit_card.id));
        } else {
          console.log('onSave!', setupIntent.id, this.getSelectedCreditCard().id);
          return onSave(setupIntent.id, this.getSelectedCreditCard().id);
        }
      })
      .then(() => {
        if (this.mounted) {
          this.setState({submitting: false});
        }
      })
      .catch((error) => {
        console.log(error);
        if (this.mounted) {
          this.setState({submitting: false});
        }
      });
  }

  private getExistingCardData(): ConfirmCardSetupData | null {
    let creditCard: ICreditCardApiData | null = this.getSelectedCreditCard();

    return creditCard ? {
      payment_method: creditCard.stripe_id
    } : null;

  }

  private getSelectedCreditCard(): ICreditCardApiData | null {
    const {creditCards, selectedCreditCardId} = this.state;

    return _.find(creditCards, value => value.id === selectedCreditCardId);
  }

  private getNewCardData(): ConfirmCardSetupData {
    const {elements} = this.props;

    return {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: this.getBillingDetails()
      }
    };
  }

  private getBillingDetails(): PaymentMethodCreateParams.BillingDetails {
    const {billingAddress} = this.props;

    return {
      'name': billingAddress.name,
      'address': {
        'city': billingAddress.city,
        'country': billingAddress.country,
        'line1': billingAddress.line1,
        'line2': billingAddress.line2,
        'postal_code': billingAddress.zip,
        'state': billingAddress.state
      }
    };
  }

  private async confirmCardSetup(): Promise<SetupIntentResult> {
    const {newCreditCard} = this.state;
    const {stripe, options} = this.props;

    let data: ConfirmCardSetupData | null = newCreditCard ? this.getNewCardData() : this.getExistingCardData();

    if (!data) return {
      error: {
        type: 'invalid_request_error',
        message: 'Invalid card data provided'
      }
    }
    if (this.setupIntent && this.setupIntent.status === "succeeded") {
      return {
        setupIntent: this.setupIntent
      };
    }
    return await stripe.confirmCardSetup(options.clientSecret, data);
  }

  private renderBillingAddress() {
    const {billingAddress, editBillingAddress} = this.props;
    if (!billingAddress) return;

    return (
      <div>
        <div className='rk-payment--details'>
          <div>
            <h3>Address</h3>
            {billingAddress.name}<br/>
            {billingAddress.line1}<br/>
            {billingAddress.line2}<br/>
            {billingAddress.city}<br/>
            {billingAddress.state}<br/>
            {billingAddress.zip}<br/>
            {billingAddress.country}<br/>
          </div>
          <div className={'rk-payment-details__edit-column'}>
            <a className='rk-link-blue' onClick={editBillingAddress}>Edit</a>
          </div>
        </div>
      </div>
    );
  }

  private renderPaymentDetails(): React.ReactElement {
    const {subscriptionPlan, editSubscriptionPlan, onCouponAdded, coupon} = this.props;

    if (subscriptionPlan == null) return;

    return (
      <Row>
        <div className='rk-payment--details'>
          <div>
            <h3>Subscription Details</h3>
            <span className={`${coupon && !coupon.trial ? 'old-price' : ''}`}>{subscriptionPlan.getPriceString()}{subscriptionPlan.getPricePeriod()}</span><br/>
            {coupon && this.renderCouponSegment(coupon, subscriptionPlan)}
            
            {subscriptionPlan.name} Plan<br/>
          </div>
          <div className={'rk-payment-details__edit-column'}>
            <a className='rk-link-blue' onClick={editSubscriptionPlan}>Edit</a>
          </div>
          {onCouponAdded && <CouponForm subscriptionPlanId={subscriptionPlan.id} coupon={coupon} onCouponAdded={this.handleCoupon}/>}
        </div>
      </Row>
    );
  }

  private renderCouponSegment(coupon, subscriptionPlan) {
    if (coupon.trial) {
      return  (
        <React.Fragment>
          <span>{coupon.trialDescription()}</span><br/>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <span className='new-price'>{subscriptionPlan.getCouponPriceString(coupon)}{subscriptionPlan.getPricePeriod()}</span><br/>
      </React.Fragment>
    )
    
  }

  private renderSelectCreditCard(): React.ReactElement {
    const {creditCards, selectedCreditCardId} = this.state;
    return (
      <React.Fragment>
        {creditCards.length === 0 ?
          <span>No existing cards</span>
          :
          <Radio.Group onChange={this.handleSelectCreditCard} value={selectedCreditCardId}>
            {creditCards.map(creditCard => (
              <Radio key={creditCard.id}
                     value={creditCard.id}>
                Card ending with {creditCard.last_four_digits}
              </Radio>
            ))}
          </Radio.Group>
        }
        <div className={'rk-new-credit-card'}>
          <span className='rk-link-blue' onClick={() => this.setState({newCreditCard: true})}>
              New Credit Card
          </span>
        </div>

      </React.Fragment>
    );
  }

  private renderCardElement(): React.ReactElement {
    const {creditCards} = this.state;

    return (
      <React.Fragment>
        <CardElement options={{hidePostalCode: true}}/>

        {creditCards.length !== 0 && (
          <div className={'rk-use-saved-credit-card'}>
            <span className='rk-link link-pointer' onClick={() => this.setState({newCreditCard: false})}>
              Use Saved Credit Card
            </span>
          </div>

        )}
      </React.Fragment>
    );
  }

  private renderButton(): React.ReactElement {
    const {elements, stripe, buttonText, errorMessage} = this.props;
    const {submitting} = this.state;

    let buttonInnerText = buttonText != null ? buttonText : 'Start Rascal Membership';
    let buttonElement = submitting ? <LoadingOutlined spin /> : buttonInnerText;

    return (
      <div>
        {errorMessage && <div className={'error-message-div error-message'}>{errorMessage.message}</div>}

        <Button className='rk-btn rk-btn-secondary rounded submit-form'
                type='primary'
                disabled={this.disableSave || submitting || !stripe || !elements}
                onClick={this.handleSubmit}>
          {buttonElement}
        </Button>
      </div>
    );
  }

  public render(): React.ReactElement {
    const {errorMessage, newCreditCard} = this.state;

    return (
      <Fragment>
        {this.renderHeader()}
        {this.renderBillingAddress()}
        {this.renderPaymentDetails()}
        <div className='rk-payment--details full-width'>
          <div>
            <h3>Credit Card</h3>
            {newCreditCard ? this.renderCardElement() : this.renderSelectCreditCard()}
          </div>
        </div>
        <br/>
        {errorMessage && <div className={'error-message-div error-message'}>{errorMessage}</div>}
        <Row justify='center'>{this.renderButton()}</Row>
      </Fragment>
    );
  }

  private renderHeader() {
    const {hideHeader} = this.props;
    if (hideHeader) return;
    return (
      <Fragment>
        <Row>
          <h1>Payment</h1>
        </Row>
        <Row>
          <p className='rk-payment--subtitle'>
            Access to Rascal starts as soon as you set up payment.
          </p>
          <p className='rk-payment--subsubtitle'>
            Cancel anytime, effective at the end of the payment period.
          </p>
        </Row>
      </Fragment>
    );
  }
}

export default observer(StripePaymentForm);
