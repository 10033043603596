import { AbstractStore } from 'models/AbstractStore';
import { RootStore } from 'models/RootStore';
import { Settings } from 'models/settings/Settings';
import { ISettingsApiData } from 'models/settings/ISettingsApiData';
import { ISettingsListener } from 'models/settings/ISettingsListener';

export class SettingsProvider extends AbstractStore {
    private listeners: ISettingsListener[] = [];

    public constructor(rootStore: RootStore) {
        super(rootStore, 'SettingsProvider');

        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public async get(): Promise<Settings | null> {
        const settings = await this.SettingsMemoryStore.read(this.SessionProvider.userId());
        return this.apiDataToModel(settings);
    }

    public async localSettings(): Promise<Settings> {
        let settings: Settings | null = await this.get();
        if (!settings) {
            settings = new Settings(this).withDefaultValues(this.SessionProvider.userId());
            this.SettingsMemoryStore.store(settings.toData(), settings.user_id);
        }
        return settings;
    }

    public async update(param: Settings): Promise<ISettingsApiData> {
        this.notifyListeners(param);
        await this.SettingsMemoryStore.store(param.toData(), this.SessionProvider.userId());
        return await this.SettingsMemoryStore.read(this.SessionProvider.userId());
    }

    private apiDataToModel(settings: ISettingsApiData): Settings {
        return new Settings(this).withData(settings);
    }

    public registerUpdateListener(listener: ISettingsListener): void {
        this.listeners.push(listener);
    }

    public unregisterUpdateListener(listener: ISettingsListener): void {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    public notifyListeners(settings: Settings): void {
        this.listeners.forEach(l => l.onSettingsUpdate(settings));
    }
}
