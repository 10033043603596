import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {ISettingsApiData} from 'models/settings/ISettingsApiData';

export class SettingsPermanentStore extends PermanentStore implements IExposedPermanentStore<ISettingsApiData> {
    protected get TableName(): string {
        return PermanentStore.TABLE_NAMES.SettingsPermanentStore;
    }

    constructor(rootStore: RootStore) {
        super(rootStore);
    }

    public getKey(id: number): string {
        return `api_settings_${id}`;
    }

    public async store(data: ISettingsApiData, id: number): Promise<void> {
        return await this.doStore<ISettingsApiData>(this.TableName, this.getKey(id), data);
    }

    public async read(id: number): Promise<ISettingsApiData | null> {
        return await this.doRead<ISettingsApiData>(this.TableName, this.getKey(id));
    }
}
