import React, {createRef, ErrorInfo, RefObject} from 'react';
import {makeObservable, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import logger from 'loglevel';
import _ from 'lodash'
import numWords from 'num-words'
import {EqualHeight, EqualHeightElement} from 'react-equal-height';
import {Button, Card, Form, FormInstance, Input, Radio, Row, Space, Tooltip} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio/interface';
import {InfoCircleOutlined} from '@ant-design/icons';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {IBillingPeriod, ISubscriptionState} from 'models/subscription/Subscription';
import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';

import DefaultLayout from 'layouts/DefaultLayout';
import PageTitle from 'components/shared/PageTitle';
import CheckCircle from "components/shared/icons/CheckCircle";

import './SubscriptionForm.scss';
import {ColorTheme} from "components/shared/RascalTheme";
import Coupon from 'models/coupon/Coupon';
import CouponApi from 'models/coupon/CouponApi';
import { ICouponApiData } from 'models/coupon/ICouponApiData';

interface IProps {
  embedded: boolean;
  selectedSubscriptionId?: number;
  coupon?: Coupon | null;
  onFinish: (selectedSubscription: SubscriptionPlan, enteredSubscriptionKey?: string) => void;
  onFinishFailed?: (errorInfo: any) => void;
}

enum SubscriptionsPages {
  DEFAULT = 'default',
  KEY_INPUT = 'key-input',
  SUCCESS = 'success'
}

interface IState {
  selectedSubscriptionId?: number;
  selectedSubscription?: SubscriptionPlan;
  enteredSubscriptionKey?: string;
  loadedSubscriptions: boolean;
  show: SubscriptionsPages;
  coupon?: Coupon | null;
}

class SubscriptionForm extends ComponentWithStore<IProps, IState> {
  @observable private subscriptionPlans: SubscriptionPlan[] = [];
  private formRef: RefObject<FormInstance> = createRef<FormInstance>();
  private mounted = false;

  constructor(props: IProps) {
    super(props);
    makeObservable(this);

    this.state = {
      show: SubscriptionsPages.DEFAULT,
      loadedSubscriptions: false
    };

    this.handleSubscriptionSelectionChange = this.handleSubscriptionSelectionChange.bind(this);
    this.handleSubscriptionKeyChange = this.handleSubscriptionKeyChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
    this.renderSubscriptionPlan = this.renderSubscriptionPlan.bind(this);
    this.finishedLoadingSubscriptions = this.finishedLoadingSubscriptions.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.checkIfCampaignHasCoupon = this.checkIfCampaignHasCoupon.bind(this);
  }

  public componentDidMount(): void {
    this.mounted = true;
    this.store.SessionProvider.authOnly();
    this.store.SubscriptionPlanProvider.fetchAllForUser()
      .then((subscriptionPlans: SubscriptionPlan[]) => {
        runInAction(() => {
          this.subscriptionPlans = subscriptionPlans
          this.checkIfCampaignHasCoupon();
          this.finishedLoadingSubscriptions();
        });
      }).catch(() => {
      this.finishedLoadingSubscriptions();
    });
  }

  public checkIfCampaignHasCoupon() {
    const {coupon} = this.props;
    if (coupon == null) {
      console.log('checkIfCampaignHasCoupon!');
      CouponApi.getUserCampaignCouponForPlans(this.subscriptionPlans.map((plan) => plan.id)).then((couponData: ICouponApiData) => {
        if (this.mounted && couponData?.code != null) {
          const campaignCoupon = new Coupon().withData(couponData);
          console.log('checkIfCampaignHasCoupon - has coupon!', campaignCoupon);
          this.setState({coupon: campaignCoupon});
        }
      });
    } else {
      if (this.mounted) {
        this.setState({coupon: coupon});
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const selectedSubscriptionId = this.props.selectedSubscriptionId;
    if (prevProps.selectedSubscriptionId !== selectedSubscriptionId && selectedSubscriptionId !== this.state.selectedSubscriptionId && !this.state.loadedSubscriptions) {
      if (selectedSubscriptionId != null) {
        const subscriptionPlan: SubscriptionPlan = this.subscriptionPlans.filter((plan) => plan.id === selectedSubscriptionId).pop();
        if (subscriptionPlan != null) {
          this.setState({
            selectedSubscriptionId: selectedSubscriptionId,
            selectedSubscription: subscriptionPlan,
            loadedSubscriptions: true
          });
          return;
        }
      }
    }
  }

  public onFinish(): void {
    const {selectedSubscription, enteredSubscriptionKey, show} = this.state;

    if (show === SubscriptionsPages.SUCCESS && !this.props.embedded) {
      window.location.href = '/home';
      return;
    }

    if (selectedSubscription) {
      window.scrollTo(0, window.screenTop);
      if (selectedSubscription.key_required) {
        if (show === SubscriptionsPages.KEY_INPUT && enteredSubscriptionKey) {
          this.submit(selectedSubscription.id, enteredSubscriptionKey);
        } else {
          this.setState({show: SubscriptionsPages.KEY_INPUT})
        }
      } else {
        this.submit(selectedSubscription.id);
      }
    }
  };

  private finishedLoadingSubscriptions() {
    let {selectedSubscriptionId} = this.props;
    if (this.mounted) {
      let subscriptionPlan: SubscriptionPlan = null;
      if (selectedSubscriptionId != null) {
        subscriptionPlan = this.subscriptionPlans.filter((plan) => plan.id === selectedSubscriptionId).pop();
      } else {
        subscriptionPlan = this.subscriptionPlans.filter((plan) => plan.name == 'Monthly').pop();
      }
      if (subscriptionPlan != null) {
        const options = {
          selectedSubscriptionId: subscriptionPlan.id,
          selectedSubscription: subscriptionPlan,
          loadedSubscriptions: true,
          show: subscriptionPlan.key_required ? SubscriptionsPages.KEY_INPUT : SubscriptionsPages.DEFAULT
        }
        this.setState(options);
        return;
      }
      this.setState({
        loadedSubscriptions: true
      });
    }
  }

  private isLoading() {
    const {loadedSubscriptions, show} = this.state;
    return show !== SubscriptionsPages.DEFAULT || loadedSubscriptions;
  }

  private submit(subscriptionPlanId: number, subscriptionPlanKey?: string): void {
    let selectedSubscriptionPlan: SubscriptionPlan = this.getSelectedSubscriptionPlan(subscriptionPlanId);

    if (selectedSubscriptionPlan.isFree()) {
      this.store.SubscriptionProvider.createSubscription({
          user_id: this.store.SessionProvider.userId(),
          subscription_plan_id: subscriptionPlanId,
          subscription_plan_key: subscriptionPlanKey
        })
        .then((result) => {
          if (result.subscription_id) {
            if (this.mounted) {
              this.setState({show: SubscriptionsPages.SUCCESS});
            }
          } else {
            logger.error('Failed to add subscription:', result);
            this.displayGenericErrorMessage(result.message);
          }
        })
        .catch(response => response.json()
          .then((result) => {
            if (result.codes.includes('ERK|602') || result.codes.includes('ERK|604')) {
              this.displayKeyInputErrorMessage();
            } else if (result.codes.includes('ERK|603')) {
              if (this.mounted) {
                this.setState({show: SubscriptionsPages.SUCCESS});
              }
            } else {
              logger.error('An error has occurred while submitting subscription:', result);
              this.displayGenericErrorMessage(result.message);
            }
          })
          .catch(error => {
            logger.error('An error has occurred while submitting subscription and it failed to parse:', response, error);
            this.displayGenericErrorMessage();
          })
        )
        .finally(() => {
          if (this.props.onFinish && ((selectedSubscriptionPlan.key_required && this.state.show === SubscriptionsPages.SUCCESS) || !selectedSubscriptionPlan.key_required)) {
            this.props.onFinish(selectedSubscriptionPlan, subscriptionPlanKey);
          }
        });
    } else {
      if (this.props.onFinish && (selectedSubscriptionPlan.key_required && subscriptionPlanKey.length > 0 || !selectedSubscriptionPlan.key_required)) {
        this.props.onFinish(selectedSubscriptionPlan, subscriptionPlanKey);
      }
    }
  }

  private onFinishFailed(errorInfo: any): void {
    logger.error('An error has occurred:', errorInfo);
    this.displayGenericErrorMessage();
    if (this.props.onFinishFailed) {
      this.props.onFinishFailed(errorInfo);
    }
  }

  private displayGenericErrorMessage(message: string = 'An error has occurred. Please try again.'): void {
    this.displayErrorMessage('generic_error_message', message);
  }

  private displayKeyInputErrorMessage(message: string = 'Invalid access key'): void {
    this.displayErrorMessage('subscription_plan_key', message);
  }

  private displayErrorMessage(name: string, message: string): void {
    this.formRef.current?.setFields([{
      name: name,
      errors: [message]
    }]);
  }

  private getSelectedSubscriptionPlan(subscriptionPlanId: number): SubscriptionPlan | undefined {
    return _.find(this.subscriptionPlans, (subscriptionPlan: SubscriptionPlan) => subscriptionPlan.id === subscriptionPlanId);
  }

  private handleSubscriptionSelectionChange(e: RadioChangeEvent): void {
    this.setState({
      selectedSubscriptionId: e.target.value,
      selectedSubscription: this.getSelectedSubscriptionPlan(e.target.value)
    });
  };

  private handleSubscriptionKeyChange(e): void {
    this.setState({enteredSubscriptionKey: e.target.value});
  }

  public renderSubscriptionPlan(subscriptionPlan: SubscriptionPlan): React.ReactElement {
    const {selectedSubscriptionId, coupon} = this.state;

    const maxNumberOfUsersDescription: string = SubscriptionPlan.getMaxUsersMessage(subscriptionPlan.max_number_of_users);
    let trialDescription: string = `${_.startCase(numWords(subscriptionPlan.trial_days))} day trial`;
    const maxNumberOfDevicesDescription: string = SubscriptionPlan.getMaxDevicesMessage(subscriptionPlan.max_number_of_devices);
    const priceTitle: string = `${subscriptionPlan.getPriceString()}`;
    const priceSubtitle: string = `${subscriptionPlan.getPriceStringMonthly()}`;

    let couponPriceTitle: string | null = null;
    let couponPriceSubtitle: string | null = null;
    let couponDescription: string | null = null;
    if (coupon && coupon.applicableTo(subscriptionPlan.id)) {
      couponPriceTitle = `${subscriptionPlan.getCouponPriceString(coupon)}`;
      couponPriceSubtitle = `${subscriptionPlan.getCouponPriceStringMonthly(coupon)}`;
      couponDescription = '';

      if (couponPriceTitle == priceTitle) {
        couponPriceTitle = null;
      }

      if (coupon.trial) {
        trialDescription = ''; // Remove trial description as coupon is overtaking the trial with its own X free.
        couponDescription = coupon.trialDescription();
      }
    }

    /*{subscriptionPlan.trial && <Card.Meta description={trialDescription}/>}*/
    /*{subscriptionPlan.max_number_of_users > 1 &&*/
    /*    <Card.Meta description={maxNumberOfUsersDescription}/>}*/
    /*<Card.Meta description={maxNumberOfDevicesDescription}/>*/
    /*<Card.Meta description={priceTitle}/>*/
    /*{subscriptionPlan.billing_period === IBillingPeriod.YEARLY &&*/
    /*    <Card.Meta className={'rk-subscriptions--card__price__subtitle'} description={priceSubtitle}/>}*/
    /*{subscriptionPlan.key_required && <Card.Meta description='Access key is required'/>}*/
    const selected = subscriptionPlan.id === selectedSubscriptionId;
    return (
      <div key={subscriptionPlan.id.toString()} className={'rk-subscriptions--card-container'}>
        <Radio
          value={subscriptionPlan.id}
          className={`rk-subscriptions--radio ${selected ? 'ant-radio-wrapper-checked' : ''}`}>
          <div className={'rk-subscriptions--card'}>
            <div className={'rk-subscriptions--card__info'}>
              <h3 className={'rk-subscriptions--card__title'}>
                {subscriptionPlan.name}
              </h3>
              <p className={'rk-subscriptions--card__price'}>
                <span className={`${couponPriceTitle ? 'card__price__old' : ''}`}>
                  {priceTitle}
                  {subscriptionPlan.billing_period === IBillingPeriod.YEARLY && <span className={'subtitle'}>{priceSubtitle}</span>}
                </span>
                {couponPriceTitle &&
                  <span className='card__price__new'>
                    {couponPriceTitle}
                    {subscriptionPlan.billing_period === IBillingPeriod.YEARLY && <span className={'subtitle'}>{couponPriceSubtitle}</span>}
                  </span>
                }
                {couponDescription &&
                  <React.Fragment>
                    <br/>
                    <span>{couponDescription}</span>
                  </React.Fragment>}
              </p>
            </div>
            <div className={'rk-subscriptions--card__tick-container'}>
              <CheckCircle checked={selected} color={ColorTheme.black} selectedColor={ColorTheme.orange}/>
            </div>
          </div>
        </Radio>
      </div>
    );
  }

  private renderSubscriptionSelection(subscriptionPlans: SubscriptionPlan[]): React.ReactElement {
    const {selectedSubscriptionId} = this.state;
    let isRequired = !selectedSubscriptionId;

    return (
      <Form.Item name='subscription_plan_id'
                 rules={[{required: isRequired, message: 'Please select a subscription plan'}]}>
        <Radio.Group
          onChange={this.handleSubscriptionSelectionChange}
          style={{minWidth: '100%'}}
        >
          <Row align='top' className={'rk-subscription-form__subscriptions'}>
            {subscriptionPlans.map(this.renderSubscriptionPlan)}
          </Row>
        </Radio.Group>
      </Form.Item>
    );
  }

  private renderKeyInput(): React.ReactElement {
    const {selectedSubscription, enteredSubscriptionKey} = this.state;

    return (
      <React.Fragment>
        {this.renderSubscriptionSelection([selectedSubscription])}
        <Form.Item name='subscription_plan_key'
                   rules={[{required: true, message: 'Please enter your subscription key'}]}>
          <Row justify='center' align='top' className='rk-subscriptions--input'>
            <Input type='text'
                   className='rk-input'
                   defaultValue={enteredSubscriptionKey}
                   placeholder='Subscription Plan Key ...'
                   onChange={this.handleSubscriptionKeyChange}
                   suffix={
                     <Tooltip title='Talk to our team about getting a key (team@rascalkids.app)'>
                       <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                     </Tooltip>
                   }/>
          </Row>
        </Form.Item>
      </React.Fragment>
    );
  }

  public renderSuccess(): React.ReactElement {
    const {selectedSubscription} = this.state;
    return (
      <div dangerouslySetInnerHTML={{__html: selectedSubscription.subscribed_success_message}}></div>
    )
  }

  public render(): React.ReactElement {
    const {selectedSubscription, show} = this.state;

    let subtitle: string;
    let onClickHandler: (event: any) => void;
    let pageContent: React.ReactElement;
    switch (show) {
      case SubscriptionsPages.DEFAULT:
        subtitle = '';
        pageContent = this.renderSubscriptionSelection(this.subscriptionPlans);
        break;
      case SubscriptionsPages.KEY_INPUT:
        subtitle = selectedSubscription.name;
        onClickHandler = () => this.setState({show: SubscriptionsPages.DEFAULT});
        pageContent = this.renderKeyInput();
        break;
      case SubscriptionsPages.SUCCESS:
        subtitle = 'Success';
        pageContent = this.renderSuccess();
        break;
    }

    const form = (
      <Form ref={this.formRef}
            className='rk-subscriptions'
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}>
        <Space className={'rk-subscriptions__container'} direction='vertical'>
          {pageContent}
          {this.isLoading() &&
              <React.Fragment>
                {show === SubscriptionsPages.DEFAULT &&
                    <React.Fragment>
                        <ul>
                            <li>Unlimited reading entertainment on all your devices.</li>
                            <li>Advert-free. Cancel anytime.</li>
                            <li>Free 3-day trial.</li>
                        </ul>
                    </React.Fragment>
                }
                  <Form.Item name='generic_error_message'>
                      <Row justify='center'>
                        {show === SubscriptionsPages.KEY_INPUT &&
                            <Button className='rk-btn rk-btn-dark rounded submit-form'
                                    type='primary'
                                    disabled={!this.state.selectedSubscriptionId}
                                    onClick={() => this.setState({show: SubscriptionsPages.DEFAULT})}>
                                Previous
                            </Button>
                        }
                          <Button className='rk-btn rk-btn-dark rounded submit-form'
                                  type='primary'
                                  htmlType='submit'
                                  disabled={!this.state.selectedSubscriptionId}>
                              Next
                          </Button>
                      </Row>
                  </Form.Item>
              </React.Fragment>
          }
        </Space>
      </Form>
    );

    if (this.props.embedded) {
      return form;
    }

    return (
      <DefaultLayout>
        <PageTitle title='Subscriptions'
                   subtitle={subtitle}
                   onClick={onClickHandler}/>
        {form}
      </DefaultLayout>
    );
  }
}

export default withStore(observer(SubscriptionForm));
