import logger from 'loglevel';
import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {IndexedDBWrapper} from 'models/IndexedDBWrapper';
import {LocalStoreWrapper} from 'models/LocalStoreWrapper';

export interface IExposedPermanentStore<T> {
    getKey(id: number): string;

    store(data: T, id?: number): Promise<void>;

    read(id: number): Promise<T | null>;
}

export interface IPermanentStore {
    doStore<T>(table: string, key: string, value: T, expiresInMinutes?: number): Promise<void>;

    doRead<T>(table: string, key: string): Promise<T | null>;

    clear(table?: string): Promise<void>;
}

enum StoreOpenState {
    OPENING,
    OPENED_IDB,
    OPENED_LOCAL
}

export abstract class PermanentStore extends AbstractStore implements IPermanentStore {
    private static _openState: StoreOpenState = StoreOpenState.OPENING;
    private static _promiseToBeOpened: Promise<IndexedDBWrapper | LocalStoreWrapper> = null;
    private static _idbStore: IndexedDBWrapper | null = null;
    private _localStore: LocalStoreWrapper | null = null;

    private static readonly DATABASE_NAME = 'Permanent';
    public static readonly TABLE_NAMES = {
        BillingAddressPermanentStore: 'BillingAddress',
        CollectionPermanentStore: 'Collection',
        InvoicePermanentStore: 'Invoice',
        SettingsPermanentStore: 'Settings',
        StoryLocalisationPermanentStore: 'Localisation',
        StoryPermanentStore: 'Story',
        StoryReleasePermanentStore: 'StoryRelease',
        StorySessionPermanentStore: 'StorySession',
        SubscriptionPlanPermanentStore: 'SubscriptionPlan',
        UserPermanentStore: 'User'
    };

    protected abstract get TableName(): string;

    private get Store(): IndexedDBWrapper | LocalStoreWrapper | null {
        switch (PermanentStore._openState) {
            case StoreOpenState.OPENING:
                return null;
            case StoreOpenState.OPENED_IDB:
                return PermanentStore._idbStore;
            case StoreOpenState.OPENED_LOCAL:
                if (this._localStore === null) {
                    try {
                        this._localStore = new LocalStoreWrapper(this.RootStore, this.DatabaseName, this.TableName);
                        logger.info(`Successfully created LocalStore for ${this.DatabaseName}`);
                    } catch (e) {
                        logger.warn(`Failed to create IDB and LocalStore for ${this.DatabaseName}`);
                    }
                }
                return this._localStore;

        }
    }

    protected constructor(rootStore: RootStore) {
        super(rootStore, PermanentStore.DATABASE_NAME);
    }

    private getStore(): Promise<IndexedDBWrapper | LocalStoreWrapper> {
        if (PermanentStore._openState !== StoreOpenState.OPENING) return Promise.resolve(this.Store);

        if (PermanentStore._promiseToBeOpened === null) {
            PermanentStore._idbStore = new IndexedDBWrapper(this.RootStore, PermanentStore.DATABASE_NAME, Object.values(PermanentStore.TABLE_NAMES), true);
            PermanentStore._promiseToBeOpened = PermanentStore._idbStore.open()
                .then(() => {
                    logger.info(`Successfully opened IDB for ${this.DatabaseName}`);
                    PermanentStore._openState = StoreOpenState.OPENED_IDB;
                })
                .catch(e => {
                    logger.info(`Failed to create IDB for ${this.DatabaseName}: ${e}`);
                    PermanentStore._openState = StoreOpenState.OPENED_LOCAL;
                })
                .then(() => this.Store);
        }

        return PermanentStore._promiseToBeOpened;
    }

    public static getExpiresAt(expiresInMinutes: number): number {
        return Date.now() + (expiresInMinutes * 60 * 1000);
    }

    public static hasExpired(expiresAt: number): boolean {
        if (expiresAt === null) return false;
        return Date.now() > expiresAt;
    }

    public async doStore<T>(table: string, key: string, value: T, expiresInMinutes?: number): Promise<void> {
        const store = await this.getStore();
        return await store.doStore(table, key, value, expiresInMinutes);
    }

    public async doRead<T>(table: string, key: string): Promise<T | null> {
        const store = await this.getStore();
        return await store.doRead<T>(table, key);
    }

    public async clear(): Promise<void> {
        const store = await this.getStore();
        return await store.clear(this.TableName);
    }
}
